import { createRouter, createWebHistory } from 'vue-router'
const constantRoutes = [
  {
    path: '/',
    component: () => import('@/views/jp/Home'),
  },
  {
    path: '/solution',
    component: () => import('@/views/jp/solutions/layout'),
    children: [
      {
        path: '',
        component: () => import('@/views/jp/solutions/index'),
        name: "jp-solutions",
        meta: { title: "Solutions" },
      },
    ],
  },
  {
    path: '/solution/detail/:id',
    component: () => import('@/views/jp/solutions/Detail'),
    name: "jp-solution-detail",
    meta: { title: "Solution Detail" },
  },
  {
    path: '/blog',
    component: () => import('@/views/jp/blog/layout'),
    children: [
      {
        path: '',
        component: () => import('@/views/jp/blog/index'),
        name: "blog",
        meta: { title: "Blog" },
      },
    ],
  },
  {
    path: '/blog/detail/:id',
    component: () => import('@/views/jp/blog/Detail'),
    name: "blog-detail",
    meta: { title: "Blog Detail" },
  },
  {
    path: '/contact',
    component: () => import('@/views/jp/Contact'),
  },
  {
    path: '/news',
    component: () => import('@/views/jp/news/layout'),
    children: [
      {
        path: '',
        component: () => import('@/views/jp/news/index'),
        name: "news",
        meta: { title: "News" },
      },

    ],
  },
    {
      path: '/news/detail/:id',
      component: () => import('@/views/jp/news/Detail'),
      name: "news-detail",
      meta: { title: "News Detail" },
    },
  {
    path: '/recruitment',
    component: () => import('@/views/jp/recruitment/layout'),
    children: [
      {
        path: '',
        component: () => import('@/views/jp/recruitment/index'),
        name: "recruitment",
        meta: { title: "Recruitment" },
      },
      {
        path: 'detail/:id',
        component: () => import('@/views/jp/recruitment/Detail'),
        name: "recruitment-detail",
        meta: { title: "Recruitment Detail" },
      },
    ],
  },
  {
    path: '/service',
    component: () => import('@/views/jp/Service'),
  },
  {
    path: '/service',
    component: () => import('@/views/jp/ServiceLineup/layout'),
    children: [
      {
        path: 'shipping-app',
        component: () => import('@/views/jp/ServiceLineup/ShippingApp'),
      },
      {
        path: 'zzzmeter-app',
        component: () => import('@/views/jp/ServiceLineup/zzzmeter-app'),
      },
      {
        path: 'shopify',
        component: () => import('@/views/jp/ServiceLineup/shopify'),
      },
      {
        path: 'okuchi-taiso',
        component: () => import('@/views/jp/ServiceLineup/okuchi-taiso'),
      },
      {
        path: 'kurua-app',
        component: () => import('@/views/jp/ServiceLineup/kurua-app'),
      },
      {
        path: 'ad-start',
        component: () => import('@/views/jp/ServiceLineup/ad-start'),
      }
    ],
  },
  // eng
  {
    path: '/en',
    component: () => import('@/views/en/Home'),
  },
  {
    path: '/en/solution',
    component: () => import('@/views/en/solutions/layout'),
    children: [
      {
        path: '',
        component: () => import('@/views/en/solutions/index'),
        name: "en-solutions",
        meta: { title: "Solutions" },
      },
    ],
  },
  {
    path: '/en/solution/detail/:id',
    component: () => import('@/views/en/solutions/Detail'),
    name: "en-solution-detail",
    meta: { title: "Solution Detail" },
  },
  {
    path: '/en/blog',
    component: () => import('@/views/en/blog/layout'),
    children: [
      {
        path: '',
        component: () => import('@/views/en/blog/index'),
        name: "en-blog",
        meta: { title: "Blog" },
      },
    ],
  },
  {
    path: '/en/blog/detail/:id',
    component: () => import('@/views/en/blog/Detail'),
    name: "en-blog-detail",
    meta: { title: "Blog Detail" },
  },
  {
    path: '/en/contact',
    component: () => import('@/views/en/Contact'),
  },
  {
    path: '/en/news',
    component: () => import('@/views/en/news/layout'),
    children: [
      {
        path: '',
        component: () => import('@/views/en/news/index'),
        name: "en-news",
        meta: { title: "News" },
      },
    ],
  },
  {
    path: '/en/news/detail/:id',
    component: () => import('@/views/en/news/Detail'),
    name: "en-news-detail",
    meta: { title: "News Detail" },
  },
  {
    path: '/en/recruitment',
    component: () => import('@/views/en/recruitment/layout'),
    children: [
      {
        path: '',
        component: () => import('@/views/en/recruitment/index'),
        name: "en-recruitment",
        meta: { title: "Recruitment" },
      },
      {
        path: 'detail/:id',
        component: () => import('@/views/en/recruitment/Detail'),
        name: "en-recruitment-detail",
        meta: { title: "Recruitment Detail" },
      },
    ],
  },
  {
    path: '/en/service',
    component: () => import('@/views/en/Service'),
  },
  {
    path: '/en/service',
    component: () => import('@/views/en/ServiceLineup/layout'),
    children: [
      {
        path: 'shipping-app',
        component: () => import('@/views/en/ServiceLineup/ShippingApp'),
      },
      {
        path: 'zzzmeter-app',
        component: () => import('@/views/en/ServiceLineup/zzzmeter-app'),
      },
      {
        path: 'shopify',
        component: () => import('@/views/en/ServiceLineup/shopify'),
      },
      {
        path: 'okuchi-taiso',
        component: () => import('@/views/en/ServiceLineup/okuchi-taiso'),
      },
      {
        path: 'kurua-app',
        component: () => import('@/views/en/ServiceLineup/kurua-app'),
      },
      {
        path: 'ad-start',
        component: () => import('@/views/en/ServiceLineup/ad-start'),
      }
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    hidden: true,
    component: () => import('@/views/error/404.vue'),
},
]


const routes = createRouter({
  history: createWebHistory(),
  routes: constantRoutes,
});

export default routes